import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import ClientLayout from "../../components/ClientLayout";

import logo from "../../../static/themes/hyped/assets/images/landing/equinox-logo.png";
import before from "../../../static/themes/hyped/assets/images/landing/variis_before.png";
import after from "../../../static/themes/hyped/assets/images/landing/variis_after.png";
import video from "../../../static/themes/hyped/assets/images/landing/variis_video.mp4";

export const query = graphql`
  {
    logo: contentfulMedia(slug: { eq: "site-logo" }) {
      media {
        fluid(maxHeight: 160, maxWidth: 1194) {
          src
        }
      }
      slug
    }
  }
`;

export default function ClientVariis({ data }) {
  const [afterRef, setAfterRef] = useState(false);
  const [beforeRef, setBeforeRef] = useState(true);
  const [label, setLabel] = useState("label-b");
  const [videoRef, setVideoRef] = useState(false);

  const splitRef = useRef();

  useEffect(() => {
    const target = splitRef.current.querySelectorAll('.letter-split');
    window.Splitting({ target: target, by: "chars" });
  }, [])


  const toggleView = () => {
    setBeforeRef(!beforeRef);
    setAfterRef(!afterRef);
    setVideoRef(!videoRef);

    let labelAB = label === 'label-b' ? "label-a" : "label-b";
    setLabel(labelAB)
  };

  return (
    <ClientLayout>
      <div className="container-client" data-primary-color="#ffffff">
        <div className="logo">
          <a className="ajax-link" data-type="page-transition" href="/">
            <img
              src={data.logo.media.fluid.src}
              className="archive-img-fluid"
              alt={data.logo.slug}
            />
          </a>
        </div>

        <div className="flex justify-space-between mt-50 inner-container">
          <div className="flex flex-col mt-20">
            <img src={logo} className="equinox-logo" alt="Equinox" />
            <span className="client-title">Email Redesign</span>

            <div className="flex flex-col mobile-reverse">
              <div className="flex items-center mt-2">
                <span
                  className={`toggle-text ${ label !== 'label-b' ? "non-active" : ""}`}
                  id="label-b"
                >
                  BEFORE
                </span>
                <div className="toggle flex">
                  <label className="switch">
                    <input type="checkbox" onClick={toggleView} />
                    <div></div>
                  </label>
                </div>
                <span
                  className={`toggle-text ${ label !== 'label-a' ? "non-active" : ""}`}
                  id="label-a"
                >
                  AFTER
                </span>
              </div>

              <div ref={splitRef}>
                <a
                  href="mailto:gb@hyped.com"
                  className="contact-button btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="link-hover letter-split">GET IN TOUCH</span>
                  <span className="link-text letter-split">GET IN TOUCH</span>
                </a>
              </div>
            </div>
          </div>

          <div className="shadow">
            <img
              id="before"
              src={before}
              alt="Before"
              className={`${!beforeRef ? "none" : ""}`}
            />
            <img
              id="after"
              src={after}
              alt="After"
              className={`${afterRef ? "block" : ""}`}
            />
            <div id="video" 
            className={`client-video ${ !videoRef ? "none" : "flex"}`}>
              <video
                preload="none"
                playsInline
                loop
                muted
                autoPlay
                controls
                className="video-content"
                alt="variis_video"
              >
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </ClientLayout>
  );
}
